<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Exercise Préparatoire pour l'Expérience 5: Question 3</h2>

      <p class="mb-4">
        Un étudiant a mesuré les données suivantes dans la décomposition du peroxyde d’hydrogène
        catalysé par l’iodure à
        <stemble-latex content="$30.0\,^\circ\text{C:}$" />
      </p>

      <v-simple-table style="margin-left: 35px; margin-bottom: 20px; max-width: 290px" dense>
        <thead>
          <th style="text-align: center">
            <stemble-latex content="$\text{Temps (s)}$" />
          </th>
          <th style="text-align: center">
            <stemble-latex content="$\text{Pression}$" />
            <stemble-latex content="$\text{d'}\ce{O2}$" />
            <stemble-latex content="$\text{formée (kPa)}$" />
          </th>
        </thead>
        <tbody>
          <tr v-for="(timeValue, i) in timeValues" :key="timeValue">
            <td style="text-align: center">
              {{ timeValues[i].toFixed(2) }}
            </td>
            <td style="text-align: center">
              {{ pressureValues[i].toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        Quelle vitesse initiale est-ce que l’étudiant a calculé pour la décomposition du peroxyde
        d’hydrogène catalysé par l’iodure à
        <stemble-latex content="$30.0\,^\circ\text{C?}$" />
      </p>

      <calculation-input
        v-model="inputs.initialRate"
        class="mb-2"
        prepend-text="$\text{Vitesse Initiale}:$"
        append-text="$\text{kPa/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'UOttawaPrelab5Q3Fr',
  components: {CalculationInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        initialRate: null,
      },
    };
  },
  computed: {
    slope() {
      return this.taskState.getValueBySymbol('slope').content;
    },
    intercept() {
      return this.taskState.getValueBySymbol('yInt').content;
    },
    timeValues() {
      const start = 0;
      const stop = 5;
      return new Array(stop - start).fill(0).map((_, i) => 0.2 * (i + 1));
    },
    pressureValues() {
      return this.timeValues.map((time) => this.slope.toFloat() * time + this.intercept.toFloat());
    },
  },
});
</script>
